<template>
    <div class="tab-list">
		<div class="title" @click="showTabs = !showTabs">
            My Account
            <i :class="['icon', showTabs ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i>
        </div>
        <div v-show="showTabs" class="tabs">
            <div v-for="(item,index) in tabList"
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="to(item.url)">
            	{{item.name}}
            	<!-- <span v-if="index == 1" class="order-num">{{`(${orderNum})`}}</span> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
        	curIndex: {
        		type: Number,
        		default: 0
        	}
        },
        data() {
            return {
                showTabs: false,
                tabList: [
                    {
                        name: 'Account Summary',
                        url: '/AccountSummary'
                    },
                    {
                        name: 'My Orders',
                        url: '/MyOrders'
                    },
                    {
                        name: 'My Address',
                        url: '/MyAddress'
                    },
					{
					    name: 'My Favorites',
					    url: '/MyFavorites'
					},
					{
					    name: 'My Coupon',
					    url: '/MyCoupon'
					},
					{
					    name: 'Leave A Message',
					    url: '/LeaveMessage'
					},
					{
					    name: 'My Profile',
					    url: '/MyProfile'
					},
					{
					    name: 'Change Password',
					    url: '/ChangePassword'
					},
					{
					    name: 'Logout',
					    url: '/logout'
					}
                ],
				orderNum: 0
            }
        },
        methods: {
            to(path) {
				if(path == '/logout') {
					this.$confirm('Are you sure to log out?', 'Tips', {
                        customClass: "confirm-box-class",
					    confirmButtonText: 'Confirm',
						cancelButtonText: 'Cancel',
					    confirmButtonClass: 'prompt-confirm-btn',
						cancelButtonClass: 'prompt-cancel-btn',
						showClose: false,
					    closeOnClickModal: false,
					    type: 'warning'
					}).then(() => {
					    this.$store.commit('logout')
                        this.$store.commit('initCartList')
					    setTimeout(() => {
					        this.to('/login')
					    },800)
					}).catch(() => {
					    
					})
				} else {
					this.$router.push({
					    path
					})
				}
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .tab-list {
            min-width: 21%;
            max-width: 21%;
        	box-sizing: border-box;
        	border-right: 1px solid #e9e9e9;
        	
        	.title {
        		width: 100%;
        		margin-bottom: 3rem;
        		font-size: 2.4rem;
        		font-weight: bold;
        		color: #333333;
        		word-wrap: break-word;
                
                .icon {
                    display: none;
                }
        	}
            
            .tabs {
                display: block !important;
                width: 100%;
                
                .tab-item {
                    width: 100%;
                    font-size: 1.4rem;
                    color: #333333;
                	word-wrap: break-word;
                    cursor: pointer;
                	
                	&:hover {
                		font-weight: bold;
                	}
                	
                	&:not(:last-child) {
                		margin-bottom: 1.8rem;
                	}
                	
                	.order-num {
                		margin-left: 2px;
                		color: #008000;
                	}
                }
                
                .active {
                    font-weight: bold;
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .tab-list {
            width: 100%;
            box-sizing: border-box;
            padding-bottom: 2.5rem;
            margin-bottom: 2.5rem;
            border-bottom: 1px solid #e9e9e9;
        	
        	.title {
                position: relative;
        		width: 100%;
        		font-size: 2rem;
        		font-weight: bold;
        		color: #333333;
        		word-wrap: break-word;
                
                .icon {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: 2.2rem;
                    font-weight: bold;
                }
        	}
            
            .tabs {
                width: 100%;
                
                .tab-item {
                    width: 100%;
                    margin-top: 1.8rem;
                    font-size: 1.4rem;
                    color: #333333;
                	word-wrap: break-word;
                    cursor: pointer;
                	
                	&:hover {
                		font-weight: bold;
                	}
                	
                	.order-num {
                		margin-left: 2px;
                		color: #008000;
                	}
                }
                
                .active {
                    font-weight: bold;
                }
            }
        }
    }
</style>